<template>
    <el-menu :default-active="$route.path" router
        background-color="#eedede"
    text-color="black"
    active-text-color="#ffd04b"
    :collapse="isCollapse"
    class="el-menu-vertical-demo"
    >
        <el-menu-item>
            <span slot="title">凯迪食品管理系统</span>
        </el-menu-item>
        <el-menu-item index="/">
            <i class="el-icon-menu"></i>
            <span slot="title">系统首页</span>
        </el-menu-item>
        <!-- <el-submenu index="/goods">
            <template slot="title">
            <i class="el-icon-location"></i>
            <span slot="title">产品管理</span>
            </template>
            
            
            <el-menu-item index="/goods/list">产品列表</el-menu-item>
            <el-menu-item index="/goods/addgoods">添加商品</el-menu-item>
            <el-menu-item index="/goods/category">产品分类</el-menu-item>
           
        </el-submenu>
        <el-submenu index="/order">
            <template slot="title">
            <i class="el-icon-location"></i>
            <span slot="title">订单管理</span>
            </template>
            
            
            <el-menu-item index="/order/orderlist">订单列表</el-menu-item>
            <el-menu-item index="/order/addorder">添加订单</el-menu-item>
            <el-menu-item index="/order/collect">汇总清单</el-menu-item>
            <el-menu-item index="/order/auditing">订单审核</el-menu-item>
           
        </el-submenu>
        <el-menu-item index="/advert">
            <i class="el-icon-menu"></i>
            <span slot="title">广告分类</span>
        </el-menu-item>
        
        <el-submenu index="5">
            <template slot="title">
            <i class="el-icon-location"></i>
            <span slot="title">系统管理</span>
            </template>
            
            
            <el-menu-item index="5-1">角色管理</el-menu-item>
            <el-menu-item index="5-2">部门管理</el-menu-item>
           
        </el-submenu> -->
       
        <MenuList :menuList="menu"></MenuList>
    </el-menu>

</template>

<script>
import MenuList from './MenuList'
import store from '@/store'
export default {
    data(){
        return {
            menu:[]
        }
    },
    props:['isCollapse'],
    components:{
        MenuList
    },
    created(){
        this.menu = store.state.menu.dyMenuList
        console.log('menulist',store.state.menu.dyMenuList)
    }
}

</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
.el-menu{
    border-right:0;
}

.el-menu .is-active{
    background:#1e78bf !important;
    color:#fff !important;
}




</style>