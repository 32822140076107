export default {
    /**
     * 
     */

    isLogin(data){
        
        if(data.toLogin && data.toLogin== 1){
            
            if(this.$route.path != '/login'){
                this.$store.commit('login/removeUser');
                localStorage.removeItem('goodsInfo');
                this.$router.push('/login');
            }
            
            return false;
        }else{
            return true;
        }
    }


}