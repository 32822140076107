<template>
    <div class="addGoods">
        <Breadcrumb></Breadcrumb>
        <el-row :gutter="10">
            <el-col :span="4">
                <div class="menu">
                    <div class='title'>产品类型列表</div>
                    <GoodsTree @category="category"></GoodsTree>
                </div>
            </el-col>
            <el-col :span="20">
                <div class="content">
                    <div class="subtitle">{{ title }}商品</div>
                    <div class="wrapper">
                        <el-form :model="goodsForm" :rules="rules" ref="goodsForm" label-width="100px" class="demo-ruleForm">
                            <el-form-item label="所属分类" prop="category_id">
                                <div class="category">
                                    {{ goodsForm.category_id }}
                                </div>
                            </el-form-item>
                            <el-form-item label="商品名称" required >
                                <el-col :span="10">
                                    <el-form-item  prop="name">
                                        <el-input v-model="goodsForm.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="info" label="商品描述">
                                        <el-input v-model.number="goodsForm.info"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>

                            <!-- <el-form-item label="商品名称" prop="name">
                                <el-input v-model="goodsForm.name"></el-input>
                            </el-form-item> -->
                            <el-form-item label="商品售价" required >
                                <el-col :span="3">
                                    <el-form-item  prop="price">
                                        <el-input v-model="goodsForm.price"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7">
                                    <el-form-item prop="cost" label="成本">
                                        <el-input v-model="goodsForm.cost"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7">
                                    <el-form-item prop="num" label="规格个数">
                                        <el-input v-model.number="goodsForm.num"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7">
                                    <el-form-item prop="stock" label="库存">
                                        <el-input v-model.number="goodsForm.stock"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>

                            <el-form-item label="规格" required >
                                <el-col :span="8">
                                    <el-form-item  prop="specification">
                                        <el-input v-model="goodsForm.specification"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="14">
                                    <el-form-item prop="retail" label="建议售价">
                                        <el-input v-model="goodsForm.retail"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <!-- <el-form-item label="商品描述" prop="info">
                                <el-input v-model="goodsForm.info"></el-input>
                            </el-form-item> -->
                            <el-form-item label="图片上传" >
                                <ImgUpload @imgUrl="imgUrl"></ImgUpload>
                            </el-form-item>
                            <el-form-item  prop="img">
                                <div class="category">
                                    {{ goodsForm.img }}
                                    {{ goodsForm.img2 }}
                                </div>
                            </el-form-item>
                            
                            <el-form-item label="推荐类型" prop="recommend">
                                <el-select v-model="goodsForm.recommend" placeholder="请选择推荐类型">
                                <el-option label="无推荐" value="0"></el-option>
                                <el-option label="热门推荐" value="1"></el-option>
                                <el-option label="最新推荐" value="2"></el-option>
                                <el-option label="热门+最新" value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('goodsForm')">保存</el-button>
                                <el-button @click="resetForm('goodsForm')">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { mapState,mapMutations } from 'vuex';
import GoodsTree from './GoodsTree.vue'
import ImgUpload from './ImgUpload.vue'

export default {
    components:{
        GoodsTree,
        ImgUpload,
    },
    data() {
      return {
        goodsForm: {
          category_id:'',
          name: '',
          price: '',
          cost: '',
          num: '',
          stock: '',
          info:'',
          recommend:'',
          img:'',
          img2:'',
          specification:'',
          retail:''

        },
        rules: {
            category_id: [
                {required: true, message: '请选择类型', trigger: 'blur' }   
            ],
          name: [
            { required: true, message: '请输入商品名称', trigger: 'blur' },
            
          ],
          price: [
            { required: true, message: '请输入价格', trigger: 'blur' },
           // { type: 'number', message: '必须是数字'}
          ],
          cost: [
            { required: true, message: '请输入成本', trigger: 'blur' },
            //{ type: 'number', message: '必须是数字'}
          ],
          num: [
            { required: true, message: '请输入规格个数', trigger: 'blur' },
            { type: 'number', message: '必须是数字'}
          ],
          stock: [
            { required: true, message: '请输入库存', trigger: 'blur' },
            { type: 'number', message: '必须是数字'}
          ],
          info: [
                {required: true, message: '请输入描述', trigger: 'blur' }   
            ],
        recommend: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
            img: [
                {required: true, message: '请上传图片', trigger: 'blur' }   
            ],
            specification: [
                {required: true, message: '请输入规格', trigger: 'blur' }   
            ],
            retail: [
                {required: true, message: '请输入建议零售价格', trigger: 'blur' }   
            ],
        }
      };
    },
    computed:{
        ...mapState('product',['rowData','title'])
    },
    methods: {
        ...mapMutations('product',['changeRowData','changeTitle']),
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //console.log(valid)
            this.addGoods(this.goodsForm);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      category(date){
        
       this.goodsForm.category_id = date.name
       console.log(this.goodsForm)
      },
      async addGoods(data){
        let res = await this.$api.addGoods(data);
        //console.log(res)
        if(res.data.msg=='ok'){
            this.$message('添加成功')
            
           this.$refs.goodsForm.resetFields();
        }
      },
      imgUrl(data){
       
        if(!this.goodsForm.img){
            this.goodsForm.img = data;
        }else{
            this.goodsForm.img2=data;
        }
        //this.$refs.upload.clearFiles();
        

      }
    },
    mounted(){
        // console.log(this.rowData)
        // console.log(this.title)
        if(this.title=='编辑'){
            this.goodsForm = this.rowData
        }
    
    },
    beforeDestroy(){
        this.changeRowData({})
        this.changeTitle('添加')
        
    }
}
</script>

<style lang="less" scoped>
.addGoods {
    padding: 10px;
    background: #f5f5f5;
}

.menu {
    background: #fff;
    border: 1px solid #ddd;
    height: 500px;

    .title {
        background: skyblue;
        height: 50px;
        line-height: 50px;
        color: #fff;
        padding-left: 10px;
        font-size: 16px;
    }
}

.content {
    background: #fff;
    // height: 500px;

    .subtitle {
        background: #eee;
        color: #333;
        padding: 10px;
        font-weight: bold;
    }

    .wrapper {
        padding: 10px;
        .category{
            color:skyblue;
        }
    }
}</style>