import { cloneDeep } from 'lodash'
import router,{resetRouter} from '@/router'
import store from '@/store'

export function ruleMenu(menu,dyMenu){
   
    let newMenu = []
    
    let arr = cloneDeep(menu)
    
    arr.forEach(ele=>{
        
        
        dyMenu.forEach(item=>{

            if(ele.meta.title==item.name){
                if(item.children && item.children.length>0){

                    ele.children = ruleMenu(ele.children,item.children)
                }
                newMenu.push(ele)
            }
        })
    })

    return newMenu
}

export function logout(){
    router.push('/login')
    store.commit('login/removeUser','')
    store.commit('menu/removeMenu')
    //this.removeMenu();
    localStorage.removeItem('goodsInfo')
    //router.addRoute({path:'/',name:'layout'})
    console.log(router);
    resetRouter()
}