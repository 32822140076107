<template>
    <div>
        <div class="header">
            <div class="icon">
                <i v-if="!isCollapse" @click="changemenu" class="iconfont icon-right-indent"></i>
                <i v-else @click='changemenu' class="iconfont icon-left-indent"></i>

            </div>
            <div class="right">
                <span>{{ nowTime }}</span>
                <span class="line">|</span>
                <span>欢迎 {{ userInfo.user }}</span>
                <span class="line">|</span>
                <span class="exit">
                    <i class="iconfont icon-tuichu" @click="logout"></i>
                </span>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapMutations, mapState } from 'vuex'
import {logout as Clogout} from '@/utils/common'
export default {
    data(){
        return {
            nowtime:''
        }
    },
    props:['isCollapse'],
    methods:{
        ...mapMutations('login',['removeUser']),
        ...mapMutations('menu',['removeMenu']),
        
        changemenu(){
            this.$emit('changeMenu')
        },
        logout(){
            // this.removeUser('')
            // this.removeMenu();
            // localStorage.removeItem('goodsInfo')
            // this.$router.push('/login')
            Clogout()
        }
    },
    created(){
        //this.nowTime=new Date();
        this.nowTime=dayjs(new Date()).format('YYYY年MM月DD日 HH:mm:ss');
       
    },
    computed:{
        ...mapState('login',['userInfo'])
    }


}

</script>

<style lang="less" scoped>
.header{
    
    background:skyblue;
    height:50px;
    width:100%;
    line-height:50px;
    display:flex;
    .icon{
        i{

            font-size:24px;
        }
    }
    .right{
        flex:1;
        text-align:right;
        font-size:14px;
        .line{
            font-size:12px;
            margin:10px;
        }
        .exit{
            display:inline-block;
            width:20px;
            padding-right:15px;
            i{font-size:18px;};
            cursor:pointer;
        }
    }

}



</style>