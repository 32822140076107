import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './router/permission'
import './plugins/element.js'
//css初始化
import './assets/css/reset.css'
import './assets/css/iconfont.css'
import common from './common'

import api from './api/index'

import Breadcrumb from '@/components/Breadcrumb'

Vue.component('Breadcrumb',Breadcrumb)

Vue.prototype.$api=api
Vue.prototype.$common = common

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
