<template>

    <div>
        <template v-for="item in menuList" >


            <el-submenu :index="item.path" v-if="item.children && item.children.length>0" :key="item.path">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span slot="title">{{ item.meta.title }}</span>
                </template>
                
                    <MenuList :menuList="item.children"></MenuList>
                <!-- <el-menu-item index="/goods/list">产品列表</el-menu-item>
                <el-menu-item index="/goods/addgoods">添加商品</el-menu-item>
                <el-menu-item index="/goods/category">产品分类</el-menu-item>
             -->
             </el-submenu>
            <el-menu-item :index="item.path" v-else :key="item.path">
                <i class="el-icon-menu"></i>
                <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
         </template>
    </div>
</template>
<script>
export default {
    name:'MenuList',
    props:['menuList'],
   
    created(){
        
        console.log('list--',this.menuList) 
    }

}
</script>