import { permission } from '@/api/index'
import { menu} from '@/router/menu'
import { ruleMenu } from '@/utils/common'
import router,{ baseRoutes } from '@/router'
import {cloneDeep} from 'lodash'
export default {
    namespaced:true,
    state:{
        dyMenuList:[]
    },
    mutations:{
        setMenu(state,payload){
            state.dyMenuList = payload 
        },
        removeMenu(state){
            state.dyMenuList = []
        }
    },
    actions:{
        async getMenuList({commit,rootState}){
            console.log('rootState.login.userInfo.token',rootState.login.userInfo.token)
            let res = await permission({token:rootState.login.userInfo.token})
            
            if(res.data.status == 200){
                console.log('后台权限--',res.data.data)
                console.log('前台权限',menu)

                let newMenu = ruleMenu(menu,res.data.data)
                console.log('匹配后',newMenu)
                commit('setMenu',newMenu)
                let base = cloneDeep(baseRoutes)
                base.children.push(...newMenu)
                console.log('base---',base)
                //router.addRoute(base)
                return base;
            }else{
                return false;
            }

            
        }
    }
}