<template>
    <div class="home">
        <Breadcrumb></Breadcrumb>
        <div class="header">
            <div class="item">
                <div class="num">
                    销售额<br>
                    {{ countObj.sale|num }}
                </div><hr/><span class="bottom">总销售额:{{ countObj.saleTotal|num }}</span>
            </div>
            <div class="item">
                <div class="num">
                    收藏量<br>
                    {{countObj.collect|num}}
                </div><hr/><span class="bottom">总收藏量:{{ countObj.collectTotal|num }}</span>
            </div>
            <div class="item">
                <div class="num">
                    访问量<br>
                    {{countObj.views|num}}
                </div><hr/><span class="bottom">总访问量:{{ countObj.viewsTotal|num }}</span>
            </div>
            <div class="item">
                <div class="num">
                    支付量<br>
                    {{ countObj.pay |num }}
                </div><hr/><span class="bottom">总支付量:{{ countObj.payTotal|num }}</span>
            </div>
            
            
            
        </div>
        <div class="content">
            <div id="main"></div>
            <div id="main2"></div>
            

        </div>
        <div class="order">
            <div class='order-item'>
                <div class="item-title">今日订单</div>
                <el-row >
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div>今日订单数</div>
                        <div>{{ order.curOrderCount }}</div>
                    </div></el-col>
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div>今日利润</div>
                        <div>{{order.curProfit}}</div>
                    </div></el-col>
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div>累计金额</div>
                        <div>{{ order.curMoney }}</div>
                    </div></el-col>
                    
                </el-row>
                
            </div>
            <div class='order-item'>
                <div class="item-title">总订单</div>
                <el-row >
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div>总订单数</div>
                        <div>{{ order.orderCount }}</div>
                    </div></el-col>
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div>总利润</div>
                        <div>{{order.profit}}</div>
                    </div></el-col>
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div>总金额</div>
                        <div>{{ order.money }}</div>
                    </div></el-col>
                    
                </el-row>
                
            </div>
            <div class='order-item'>
                <div class="item-title">快捷入口</div>
                <el-row >
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div class="bot">产品管理</div>
                    </div></el-col>
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div class="bot">产品管理</div>
                    </div></el-col>
                    <el-col :span="8"><div class="grid-content bg-purple">
                        <div class="bot">产品管理</div>
                    </div></el-col>
                    
                    
                </el-row>
                
            </div>
            
        </div>
        
    </div>
    


</template>


<script>
import * as echarts from 'echarts';

export default {
    data(){
        return {
            countObj:{},
            order:{}
        }
    },
    filters:{
        num(val){
            if(!val)return '';
            return val.toLocaleString()
        }
    },
    mounted(){
        this.getHomeCount();
        this.getHomeFormat();
        // this.line();
        //this.pie();
        this.getHomeOrder();
    },
    methods:{
        line(xData,yLineData,yBarData){
            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
            tooltip:{
                trigger:'axis'
            },
            xAxis: {
                type: 'category',
                data: xData
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                data: yLineData,
                type: 'line',
                smooth: true
                },
                {
                data: yBarData,
                type: 'bar',
                smooth: true
                }
            ]
            };

            option && myChart.setOption(option);

        },
        pie(data){
            var chartDom = document.getElementById('main2');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
            title: {
                // text: 'Referer of a Website',
                // subtext: 'Fake Data',
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [
                {
                name: '销售额',
                type: 'pie',
                radius: '50%',
                data,
                // data: [
                //     { value: 1048, name: 'Search Engine' },
                //     { value: 735, name: 'Direct' },
                //     { value: 580, name: 'Email' },
                //     { value: 484, name: 'Union Ads' },
                //     { value: 300, name: 'Video Ads' }
                // ],
                emphasis: {
                    itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
                }
            ]
            };

            option && myChart.setOption(option);
            
        },
        async getHomeCount(){
            let res = await this.$api.getHomeCount();
            let isLogin = this.$common.isLogin.call(this,res.data)
            if(!isLogin)return;
            this.countObj = res.data;
        },
        async getHomeFormat(){
            let res = await this.$api.getHomeFormat();
            let isLogin = this.$common.isLogin.call(this,res.data)
            if(!isLogin)return;
            let resData = res.data.data;
            let xData=[],yLineData=[],yBarData=[],pieData=[];
            resData.forEach(item=>{
                xData.push(item.name);
                yLineData.push(item.amount);
                yBarData.push(item.num)
                let obj = {};
                obj.name=item.name;
                obj.value=item.amount;
                pieData.push(obj);
            });
            this.line(xData,yLineData,yBarData);
            this.pie(pieData);
        },
        async getHomeOrder(){
            let res = await this.$api.getHomeOrder();
            let isLogin = this.$common.isLogin.call(this,res.data)
            if(!isLogin)return;
            this.order=res.data;

        }
    }
}


</script>

<style scoped lang="less">

.home{
    // background:rgb(239, 239, 241);
    background:#f5f5f5;
    
    .header{
        display:flex;
        .item{
        width:200px;
        height:100px;
        background:rgb(216, 81, 81);
        border-radius:10px;
        margin:10px auto;
        font-size:18px;
        line-height:25px;
        color:white;
        font-weight:600;
        .bottom{
            font-weight:400;
            font-size:16px;
            line-height:20px;
            margin-left:8px;
        }
        .num{
            margin:2px 5px;
        }
        
        
        
    }
    }
    .content{
        display:flex;
        #main{
            width:60%;
            height:300px;
            
            
            margin:10px auto;
        }
        #main2{
            width:35%;
            height:300px;
        
            
            margin:10px auto;
        }
    }
    .order{
        display:flex;
        // background:white;
        .order-item{
            width:28%;
            text-align:center;
            height:120px;
            background:white;
            margin:0 auto;
            line-height:35px;
            box-shadow:0 0 7px 7px #dacbcb;
            font-size:14px;
            .item-title{
                font-size:20px;
                font-weight:800px;
                height:50px;
                // background:blue;
                line-height:50px;
                padding-left:4px;
                border-bottom:1px solid #aa9191;;
            }
            .bot{
                width:90px;
                height:40px;
                background:skyblue;
                border-radius:5px;
                margin-top:15px;
            }
        }
    }

}



</style>