<template>
    <div class="page">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</template>
<script>
//import { number } from 'echarts';

export default {
    props:{
        pageSize:{
            type:Number,
            default:10
        },
        total:{
            type:Number,
            required:true
        }
    },
    data(){
        return {
            currentPage:1
        }
    },
    methods:{
        handleCurrentChange(current){
            //console.log(current)
            this.$emit('pageCurrentChange',current)

        },
        handleSizeChange(size){
            this.$emit('pageSizeChange',size)

        }

    }

}


</script>

<style lang="less" scoped>
.page{
    background:white;
    border-radius:10px;
    width:100%;
    text-align:center;
    padding:20px;
}

</style>