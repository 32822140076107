import Vue from 'vue'
import Vuex from 'vuex'
import product from './modules/product'
import login from './modules/login'
import menu from './modules/menu'
import cteatePersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    product,login,menu
  },
  plugins:[
    cteatePersistedstate({
      key:'goodsInfo',
      paths:['product','login']
    })
  ]
  
})
