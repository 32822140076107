<template>
    <div class="addOrder">
        <Breadcrumb></Breadcrumb>
        <div class="search">
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">
              <el-input
                placeholder="请输入用户名字"
                v-model="user_id"
                clearable>
              </el-input>
            </div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">
              <el-autocomplete
                clearable
                v-model="state"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入商品简写"
                @select="handleSelect"
                >
              </el-autocomplete>
            </div></el-col>
            <el-col :span="10"><div class="grid-content bg-purple">
              <span class="info">总件数:  {{ countNum }} 总金额: {{ amount }}</span>
            </div></el-col>
          </el-row>
         
           
        </div>
        <div>
          <el-table
            :data="tableData"
            border
            style="width: 100%">
            <!-- <el-table-column
              type="selection"
              width="55">
            </el-table-column> -->
            <el-table-column prop="goods_id" label="ID" width="110"></el-table-column>
            <el-table-column prop="name" label="商品名称" width="110"></el-table-column>
            <el-table-column prop="price" label="售价" ></el-table-column>
            <el-table-column prop="num" label="数量" >
              <template slot-scope="scope">
       
                <el-input v-model="scope.row.num" @input = 'handleInput' placeholder="请输入内容"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="订单金额"></el-table-column>
            <el-table-column prop="profit" label="利润" ></el-table-column>
            <el-table-column prop="cost" label="成本" ></el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="deleteRow(scope.$index, tableData)"
                    type="text"
                    size="small">
                    移除
                  </el-button>
                </template>
            </el-table-column>
          </el-table>
        </div>
        <el-button type="primary" @click="submitOrder">提交</el-button>
    </div>

</template>
<script>
  export default {
    data() {
      return {
        restaurants: [],
        state: '',
        tableData:[],
        num:1,
        user_id:'',
        amount:0
        
      };
    },
    computed:{
      initProfit(){
        this.amount=0
        this.tableData.forEach(item=>{
          
          item.amount=Math.round(item.num*item.price*100)/100;
          item.profit = Math.round((item.price-item.cost)*item.num*100)/100;
          this.amount += item.amount;
        });
        //console.log('jssuan')
      
      },
      countNum(){
        let number =0;
         this.tableData.forEach(function(item){
          
          if(!isNaN(parseInt(item.num))){
            number += parseInt(item.num);
          }
        });
        return number;
      }
    },
    methods: {
      async submitOrder(){
        let data =await this.$api.submitOrder({data:JSON.stringify(this.tableData),user_id:this.user_id});
        if(data.data.status==200){
          this.tableData=[];
          this.user_id='';
          this.$message('提交成功');
        }

        // console.log(JSON.stringify(this.tableData))
      },
      
      async querySearchAsync(queryString, cb) {
        //console.log(queryString)

        if(queryString){

            var data=await this.$api.selectGoods({queryString});
            var results = Object.values(data.data)
        }else{
            var results = [];
        };
        //var restaurants = Object.values(data.data)
        //var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
       
        cb(results);
      },
    //   createStateFilter(queryString) {
    //     return (state) => {
    //       return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    //     };
    //   },
      handleSelect(item) {
       // item['num']=5;
       item.name=item.value
       delete(item.value)
       item.ordernum=0;
       
       this.tableData.unshift(item);
       let number = this.tableData.indexOf(item)
       //console.log(number)
       this.$set(this.tableData[number],'num',1)
       this.initProfit;
       this.state ='';
       //console.log(this.tableData);
      },
      deleteRow(index, rows) {
        console.log(index,rows)
        rows.splice(index, 1);
      },
      handleInput(value){
        //console.log('kdf',value)
        this.initProfit;

      }
    },
    
  };
</script>
<style lang="less" scoped>
.addOrder{
    margin:10px;
    
    .search{
      margin:10px;
      .info{
        line-height:40px;
      }
    }
}
</style>