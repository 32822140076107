<template>

    <div>
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div>
</template>

<script>
  export default {
    
    
    mounted(){
      this.getCategory()
    },
    data() {
      return {
        data: [{
          label: '糖果 1',
          children: [{
            label: '硬糖 1-1',
            children: [{
              label: '1硬糖 1-1-1'
            },
          {
            label:'2硬糖'
          }]
          }]
        }, {
          label: '一级 2',
          children: [{
            label: '二级 2-1',
            children: [{
              label: '三级 2-1-1'
            }]
          }, {
            label: '二级 2-2',
            children: [{
              label: '三级 2-2-1'
            }]
          }]
        }, {
          label: '一级 3',
          children: [{
            label: '二级 3-1',
            children: [{
              label: '三级 3-1-1'
            }]
          }, {
            label: '二级 3-2',
            children: [{
              label: '三级 3-2-1'
            }]
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'name'
        }
      };
    },
    mounted(){
      this.getCategory()
    },
    methods: {
      handleNodeClick(data) {
        this.$emit('category',data);
        //console.log(data.name)
      },
      async getCategory(){
        let data=await this.$api.getCategory()
        this.data = data.data
        // console.log(data)
      }
    }
  };
</script>