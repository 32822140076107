<template>
    <el-breadcrumb separator="/" class="breadcrumb">
        <el-breadcrumb-item v-for="item in navData" :key="item.path" :to="{ path:item.path }">{{ item.meta.title }}</el-breadcrumb-item>
        
    </el-breadcrumb>
</template>
<script>
export default {
    name:'Breadcrumb',
    created(){
        //console.log(this.$route)
    },
    computed:{
        navData(){
            let matche = this.$route.matched;
            matche[0].path='/';
            //console.log('---',matche);
            return matche;
        }
    }
}

</script>
<style scoped>
.breadcrumb{
    margin:5px 10px 10px 10px; 
}

</style>