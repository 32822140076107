const base = {
    //host:'http://k.com',
    host:'https://kaidiy.cn',
    homeData:'/kaidiadmin/indexdata', //首页统计数据
    format:'/kaidiadmin/homeformat',// 首页折线图
    homeOrder:'/kaidiadmin/homeorder',//首页今日订单
    goodsList:'/kaidiadmin/goodsList',//产品列表页数据
    goodsSearch:'/kaidiadmin/goodsSearch', //搜索商品
    deleteGoods:'/kaidiadmin/deleteGoods',//删除商品  id
    getCategory:'/kaidiadmin/getCategory',//获取分类列表
    addGoods:'/kaidiadmin/addGoods',
    imgUpload:'/kaidiadmin/imgUpload', //图片上传
    getOrderList:'/kaidiadmin/getOrderList',//获取订单列表
    selectGoods:'/kaidiadmin/selectGoods',// 添加订单选择商品
    submitOrder:'/kaidiadmin/submitOrder', //提交订单
    getOrderDetail:'/kaidiadmin/getOrderDetail',  //获取订单详情
    login:'/kaidiadmin/login', // 登录接口
    permission:'/kaidiadmin/permission', //权限
    getAdmin:'/kaidiadmin/system/getadmin',//获取admin列表
    adminIsuse:'/kaidiadmin/system/adminIsuse',//admin 是否启用开关接口
    getUserRole:'/kaidiadmin/system/getUserRole',//获取一个admin已分配的角色
    updateUserRole:'/kaidiadmin/system/updateUserRole',//更改一个admin已分配的角色
    addUser:'/kaidiadmin/system/addUser',//新增用户
    searchUser:'/kaidiadmin/system/searchUser',//搜索用户接口
    deleteUser:"/kaidiadmin/system/deleteUser",//删除用户接口
    getRole:'/kaidiadmin/system/getRole',//获取角色列表
    getMenu:'/kaidiadmin/system/getMenu',//获取全部菜单权限
    getRoleMenuId:'/kaidiadmin/system/getRoleMenuId',//获取一个角色的权限菜单的id
    updateRoleMenu:'/kaidiadmin/system/updateRoleMenu',//更新一个角色的权限菜单
    getChatList:'/kaidiadmin/chat/getChatList', //获取聊天列表
    getChat:'/kaidiadmin/chat/getChat', //获取单个聊天记录
    addChat:'/kaidiadmin/chat/addChat',//增加聊天
    changeOrderStatus:'/kaidiadmin/changeOrderStatus',//改变订单状态

}

export default base