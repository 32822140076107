import GoodsManage from '@/views/GoodsManage'
import List from '@/views/GoodsManage/List'
import Category from '@/views/GoodsManage/Category'
import AddGoods from '@/views/GoodsManage/AddGoods'

import OrderManage from '@/views/OrderManage'
import OrderList from '@/views/OrderManage/OrderList'
import AddOrder from '@/views/OrderManage/AddOrder'
import Auditing from '@/views/OrderManage/Auditing'
import Collect from '@/views/OrderManage/Collect'
import Property from '@/views/Property'
import Deposit from '@/views/Property/Deposit'

import System from '@/views/System'
import User from '@/views/System/User'
import Role from '@/views/System/Role'
import MenuList from '@/views/System/MenuList'


export const menu= [
    {
        path:'/goods',
        name:'goods',
        component:GoodsManage,
        meta:{
          title:'产品管理'
        },
        redirect:'/goods/list',
        children:[
          {
            path:'/goods/list',
            name:'list',
            component:List,
            meta:{
              title:'产品列表'
            }
          },
          {
            path:'/goods/category',
            name:'category',
            component:Category,
            meta:{
              title:'产品分类'
            }
          },
          {
            path:'/goods/addgoods',
            name:'addgoods',
            component:AddGoods,
            meta:{
              title:'添加产品'
            }
          }
        ]
      },{
        path:'/order',
        name:'order',
        component:OrderManage,
        meta:{
          title:'订单管理'
        },
        redirect:'/order/orderlist',
        children:[
          {
            path:'/order/orderlist',
            name:'orderlist',
            component:OrderList,
            meta:{
              title:'订单列表'
            }
          },
          {
            path:'/order/addorder',
            name:'addorder',
            component:AddOrder,
            meta:{
              title:'添加订单'
            }
          },
          {
            path:'/order/auditing',
            name:'auditing',
            component:Auditing,
            meta:{
              title:'订单审核'
            }
          },
          {
            path:'/order/collect',
            name:'collect',
            component:Collect,
            meta:{
              title:'订单汇总'
            }
          }
        ]
      },{
        path:'/property',
        name:'property',
        component:Property,
        meta:{
          title:'资产管理'
        },
        children:[
          {
            path:'/property/deposit',
            name:'deposit',
            component:Deposit,
            meta:{
              title:'存款列表'
            }
          }
        ]
      },{
        path:'/system',
        name:'system',
        component:System,
        meta:{
          title:'系统管理'
        },
        redirect:'/sysetm/user',
        children:[
          {
            path:'/sysetm/user',
            name:'user',
            component:User,
            meta:{
              title:'用户管理'
            }
          },
          {
            path:'/system/role',
            name:'role',
            component:Role,
            meta:{
              title:'角色管理'
            }
          },
          {
            path:'/system/menulist',
            name:'menulist',
            component:MenuList,
            meta:{
              title:'菜单管理'
            }
          }
        ]
      }
]