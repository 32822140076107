<template>
    <div class="user">
        <Breadcrumb></Breadcrumb>
        <div class="search">
            <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
            <el-form-item label="查询用户">
                <el-input v-model="searchInfo.name" placeholder="账号/名字"></el-input>
            </el-form-item>
            
            <el-form-item>
                <el-button type="primary" @click="searchUser">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="" @click="reset">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="dialogNewUser=true">添加用户</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="showAll">显示全部</el-button>
            </el-form-item>
            </el-form>
        </div>
        <div class="tables">
            <el-table
            :data="userList"
            border
            style="width: 100%">
                <el-table-column
                prop="id"
                label="编号"
                width="180">
                </el-table-column>
                <el-table-column
                prop="account"
                label="账号"
                width="180">
                </el-table-column>
                <el-table-column
                prop="name"
                label="姓名"
                width="180">
                </el-table-column>
                <el-table-column
                prop="create_time"
                label="创建日期">
                </el-table-column>
                <el-table-column
                prop="isuse"
                label="是否启用">

                    <template slot-scope="scope">
                        
                        
                        <el-switch v-model="scope.row.isuse" :active-value="1" :inactive-value="0" 
                        @change="isuseSwitch($event,scope.row,scope.$index)"></el-switch>
                    </template>


                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="200">
                    <template slot-scope="scope">
                        <el-button @click="assignRole(scope.row)" type="text" size="small">分配角色</el-button>
                        <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="deleteUser(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="分配角色" >
            <el-select v-model="selectValue" multiple placeholder="请选择" size="medium" 
            @change="selectChange">
                <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmDialog">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="添加用户"
            :visible.sync="dialogNewUser"
            width="50%"
            >
            <el-form ref="form" :model="userForm" label-width="80px">
                <el-form-item label="账号">
                    <el-input v-model="userForm.account"></el-input>
                </el-form-item>
                <el-form-item label="名字">
                    <el-input v-model="userForm.name"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="userForm.password"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <el-button type="primary" @click="addUser">立即创建</el-button>
                    
                </el-form-item>
                </el-form>
        </el-dialog>
        <el-dialog
            title="编辑用户"
            :visible.sync="dialogEditUser"
            width="50%"
            >
            <el-form ref="form" :model="editUserForm" label-width="80px">
                <el-form-item label="账号">
                    <el-input v-model="editUserForm.account"></el-input>
                </el-form-item>
                <el-form-item label="名字">
                    <el-input v-model="editUserForm.name"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="editUserForm.password"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <el-button type="primary" @click="editUser">立即修改</el-button>
                    
                </el-form-item>
                </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return {
            searchInfo:{
                name:''
            },
            userList:[],
            dialogVisible:false,
            selectValue:[],
            currentUserId:null,
            selectOptions:[
                {
                    value:1,
                    label:"超级管理员"
                },
                {
                    value:2,
                    label:"商品管理员"
                },
                {
                    value:3,
                    label:"订单管理员"
                },
            ],
            dialogNewUser:false,
            dialogEditUser:false,
            userForm:{
                account:'',
                name:'',
                password:''
            },
            editUserForm:{
                account:'',
                name:'',
                password:'',
                id:''
            },
        }
    },
    methods:{
        searchUser(){
            console.log(this.searchInfo)
            this.$api.searchUser({name:this.searchInfo.name}).then(res=>{
                if(res.data.status==200){
                    this.userList=res.data.data
                    this.searchInfo.name='';
                }else if(res.data.status==204){
                    this.$message('没有查询到数据')
                }
            })
        },
        reset(){
            this.searchInfo.name='';
        },
        async getUser(){
            const res = await this.$api.getAdmin();
            if(res.data.status==200){
                this.userList=res.data.data
            }
        },
        isuseSwitch(e,row,index){
            console.log(e,row,index)
            const params={id:row.id,isuse:e}
            this.$api.adminIsuse(params).then(res=>{
                if(res.data.status==200){
                    this.$message({
                        message:'修改成功',
                        type:'success'
                    })
                }else{
                    this.$message.error('修改失败')
                }
            })
        },
        assignRole(row){
            this.dialogVisible=true;
            this.currentUserId=row.id;
            console.log(row)
            this.$api.getUserRole({id:row.id}).then(res=>{
                console.log(res)
                if(res.data.status==200){
                    
                    this.selectValue=res.data.data.map(item=>item.role_id);
                }
            })
        },
        selectChange(data){
            console.log(data,this.selectValue)
        },
        confirmDialog(){
            this.dialogVisible=false;
            this.$api.updateUserRole({id:this.currentUserId,role:this.selectValue}).then(res=>{
               if(res.data.status==200){
                this.$message({
                    message:'分配角色成功',
                    type:'success'
                })
               }else{
                this.$message.error('发生错误，请稍后再试')
               }
            })


        },
        addUser(){
            
            this.$api.addUser(this.userForm).then(res=>{
                if(res.data.status==200){
                    this.$message({
                        message:'添加成功',
                        type:'success'
                    })
                    this.dialogNewUser=false;
                    this.userForm={
                        account:'',
                        name:'',
                        password:''
                    }
                    this.getUser();
                }else{
                    this.$message.error('添加失败，请稍后再试')
                }
            })
        },
        showAll(){
            this.getUser();
        },
        edit(row){
            this.dialogEditUser=true;
            this.editUserForm.id=row.id;
            this.editUserForm.account=row.account;
            this.editUserForm.name=row.name;
            
            console.log(this.editUserForm)
           
        },
        editUser(){
            this.$api.addUser(this.editUserForm).then(res=>{
                if(res.data.status==200){
                    this.$message({
                        message:'修改成功',
                        type:'success'
                    })
                    this.dialogNewUser=false;
                    this.editUserForm={
                        account:'',
                        name:'',
                        password:'',
                        id:''
                    }
                    this.getUser();
                }else{
                    this.$message.error('修改失败，请稍后再试')
                }
            })
        },
        deleteUser(row){
            this.$confirm(`确认删除用户${row.name}吗`,'提示',{
                comfirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                this.$api.deleteUser({id:row.id}).then(res=>{
                    if(res.data.status==200){
                        this.$message({
                        message:'删除成功',
                        type:'success'
                        })
                        this.getUser();
                    }else{
                        this.$message.error('删除失败，请稍后再试')
                    }
                })

            }).catch(()=>{
                this.$message({
                    type:'indo',
                    message:"已取消删除"
                })
            })
        }
        

    },
    created(){
        this.getUser();
    },
}


</script>

<style scoped lang="less">
.user{
    padding:10px;
    background:#f5f5f5;
    .search{
        background:white;
       margin:10px 0;
        border-radius:10px;
        padding:10px;
        .el-form-item{
            margin-bottom:0
        }
    }
    .tables{
        padding:10px;
        background:white;
        border-radius:10px;
    }
}




</style>