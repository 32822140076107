export default {
    namespaced:true,
    state:{
        rowData:{},
        title:'添加'
    },
    mutations:{
        changeRowData(state,payload){
            state.rowData = payload ;
        },
        changeTitle(state,title){
            state.title = title

        }
    }
}