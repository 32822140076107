import router from './index'
import store from '@/store'
import { logout } from '@/utils/common'

router.beforeEach((to,from,next)=>{
  console.log(to)
 
  //to.matched.length>0 && to.matched[0].meta.isLiogn
  // !to.name || to.matched[0].meta.isLiogn
  
  if(to.matched.length==0 || to.matched[0].meta.isLiogn){
    if(store.state.login.userInfo.token){
      console.log('ceshi')

        if(!store.state.menu.dyMenuList.length){
          
           store.dispatch('menu/getMenuList').then(data=>{

              if(data){
                
                console.log('加载路由');
                router.addRoute(data)
              }else{
                logout()
              }
            })
        }
      next()
    }else{
      next('/login')
      // next();
    }

  }else{
    // if(to.name==null){
    //   next('/login')
    // }else{
    //   next()
    // }
    next();
  }
})