import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'
import Login from '@/views/Login'
import Home from '@/views/Home'






Vue.use(VueRouter)

export const baseRoutes = {
  path:'/',
  component:Layout,
  meta:{
    title:'首页',
    isLogin:true
  },
  children:[
    {
      path:'/',
      name:'home',
      component:Home,
      meta:{
        title:'首页'
      }
    }

  ]
}

export const routes = [
  
  {
    path:'/login',
    name:'login',
    component:Login,
    meta:{
      title:'登录',
      isLogin:false
    }
  }
  
]

const createRouter = ()=>new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const router = createRouter()

export function resetRouter(){
  const newRouter = createRouter()
  router.matcher = newRouter.matcher

}


export default router