<template>
    <el-drawer
        title="订单详情"
        :visible.sync="drawer"
        :direction="direction"
        size='100%'
        @open="handleOpen"
        
        :before-close="handleClose">
        <div class="detail">
            订单信息
            <el-table
                :data="titleData"
                border
                style="width: 100%">
               
                <el-table-column prop="ordernum" label="订单编号" width="110"></el-table-column>
                <el-table-column prop="user" label="用户" ></el-table-column>
                <el-table-column prop="status" label="状态" ></el-table-column>
                <el-table-column prop="amount" label="订单金额"></el-table-column>
                <el-table-column prop="profit" label="利润" ></el-table-column>
                <el-table-column prop="date" label="日期"  width="170"></el-table-column>
                <el-table-column prop="address" label="地址" ></el-table-column>
                
            </el-table>
           订单详情
            <el-table
            :data="detailData"
            border
            style="width: 100%">
           
            <el-table-column prop="goods_id" label="ID" width="110"></el-table-column>
            <el-table-column prop="name" label="商品名称" width="110"></el-table-column>
            <el-table-column prop="price" label="售价" ></el-table-column>
            <el-table-column prop="num" label="数量" >
            </el-table-column>
            <el-table-column prop="amount" label="订单金额"></el-table-column>
            <el-table-column prop="profit" label="利润" ></el-table-column>
            <el-table-column prop="cost" label="成本" ></el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="deleteRow(scope.$index, tableData)"
                    type="text"
                    size="small">
                    移除
                  </el-button>
                </template>
            </el-table-column>
          </el-table>
        </div>
    </el-drawer>
</template>
<script>
export default {
  
    data() {
      return {
        drawer:false,
        direction: 'rtl',
        ordernum:'',
        detailData:[],
        titleData:[]

      };
    },
    methods: {
      handleClose(done) {
        done();
        this.detailData=[]
        this.titleData=[]
        this.ordernum=''
      },
      async handleOpen(){
        //console.log(this.ordernum)
        let data=await this.$api.getOrderDetail({ordernum:this.ordernum})
        //console.log(data)
        if(data.status==200){
            this.detailData = data.data.orderDetail;
        }
      },
     
     
    },
   
    
}
</script>
<style lang="less" scoped>
.detail{
    width:80%;
   
    margin:0 auto;
    text-align:center;
    line-height:50px;
}
</style>