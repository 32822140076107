<template>
    <div class="list">
      <Breadcrumb></Breadcrumb>
        <div class="header-search">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="产品名称">
                    <el-input v-model="formInline.name" placeholder="产品名称"></el-input>
                </el-form-item>
                <el-form-item label="添加时间">
                    <el-date-picker
                        v-model="formInline.time"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onList">全部商品列表</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="add-goods">
            <el-button type="warning" @click="addGoods">添加商品</el-button>
            <el-button type="danger" >批量删除</el-button>
        </div>
        <div class="list-table">
            <el-table
                :data="goodsList"
                border
                style="width: 100%">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                prop="id"
                label="商品ID"
                width="80">
                </el-table-column>
                <el-table-column
                prop="name"
                label="商品名称"
                width="180">
                </el-table-column>
                <el-table-column
                prop="info"
                label="商品描述"
                width="100">
                </el-table-column>
                <el-table-column
                prop="price"
                label="商品价格"
                width="80">
                </el-table-column>
                <el-table-column
                prop="num"
                label="规格数量"
                width="80">
                </el-table-column>
                <el-table-column
                prop="stock"
                label="商品库存"
                width="80">
                </el-table-column>
                
                
                <el-table-column
                prop="publish"
                label="是否上架">
                </el-table-column>
                <el-table-column label="操作"
                width="200">
                <template slot-scope="scope">
                    <el-button
                    icon="el-icon-edit"
                    size="mini"
                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button
                    icon="el-icon-delete"
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
                </el-table-column>
            </el-table>


        </div>
        <Pagination 
        :pageSize="pageSize"
        :total="listTotal"
        @pageCurrentChange="pageCurrentChange"
        @pageSizeChange="pageSizeChange"
        v-if="!searchStatus"
        ></Pagination>
    </div>


</template>
<script>
import Pagination from '@/components/Pagination'
import { mapMutations } from 'vuex'


export default {
  components:{
    Pagination
  },

    data() {
      return {
        formInline: {
          name: '',
          time: ''
        },
        goodsList:[],
        pageSize:10,
        listTotal:0,
        searchStatus:false,
        currentPage:1

      }
      
    },
    mounted(){
      this.getGoodsList({page:1,pagesize:this.pageSize})
      //console.log(this.$store.state.product.rowData)
      
     
      
    },
    methods: {
      ...mapMutations('product',['changeRowData','changeTitle']),
      addGoods(){
        this.changeTitle('添加')
        this.$router.push('/goods/addgoods')
      },
      onSearch() {
        this.searchStatus = true;
        //console.log(this.formInline);
        if(!this.formInline.name){
          this.$message.error('请输入信息再去搜索')
          return;
        };
        //console.log('sousuo')
        this.goodsSearch({search:this.formInline.name})
      },
      onList(){
        this.searchStatus = false;
        this.getGoodsList({page:1,pagesize:this.pageSize})
      },
      handleEdit(index, row) {
        console.log(index, row);
        this.changeRowData(row)
        this.changeTitle('编辑')
        this.$router.push('/goods/addgoods')
      },
      handleDelete(index, row) {
        //console.log(index, row.id);
        this.$confirm('此操作将永久删除该商品-'+row.name+', 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          this.$api.deleteGoods({id:row.id})
          .then(res=>{
            if(res.data.status===200){
                this.getGoodsList({page:this.currentPage,pagesize:this.pageSize})
                this.$message({
                type: 'success',
                message: '删除成功!'
                });
            }else{
              this.$message.error('删除失败,系统错误');
            };

          });
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      async getGoodsList(params){
        let data = await this.$api.getGoodsList(params)
        let isLogin = this.$common.isLogin.call(this,data.data)
        if(!isLogin)return;
        if(data.status==200){
          //console.log(data)
          this.goodsList = data.data.data;
          this.listTotal = data.data.count;
        }

      },
      async goodsSearch(params){
        let data = await this.$api.goodsSearch(params)
        let isLogin = this.$common.isLogin.call(this,data.data)
        if(!isLogin)return;
        if(data.data.count==0){
          this.$message({message:'没有数据'})
          return;
        }
        this.goodsList = data.data.data;
        //this.listTotal =data.data.count;
      },
      pageCurrentChange(current){
        //console.log('--'+current)
        this.currentPage = current;
        this.getGoodsList({page:current,pagesize:this.pageSize})

      },
      pageSizeChange(size){
        //console.log('---'+size)
        this.pageSize = size;
      }
    }
}

</script>

<style lang="less" scoped>

.list{
    padding:10px;
    background:#f5f5f5;
    .header-search{
        padding:10px;
        background:white;
        border-radius:5px;
        .el-form-item {
            margin-bottom: 0;
        }
    }
    .add-goods{
        background:white;
        padding:15px;
        border-top:2px solid #f5f5f5;
        border-radius:5px;
    }
}




</style>