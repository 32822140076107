<template>
    <div>
        <Breadcrumb></Breadcrumb>
        <div class="container">
            <div class="chat-list">
                <h4>消息列表</h4>
                <div class="list-item" v-for="(item,index) in chatList"  @click="selectChat(item.user_id)">
                        {{item.user_id }}
                
                </div>
               

            </div>
            <div class="chat" v-show="showChat">
                <div class="chat-box" @scroll="scrollEvent">

                    <div class="item" v-for="(item,index) in list"  >
                        <div :class="['message',item.isadmin?'right':'left']">
    
    
                            <span class="text">
                                {{ item.title }}
                            </span>
                        </div>
    
                    </div>
                </div>
                <div class="input">
                    <input type="text" v-model="value">
                    <button @click="send">发送</button>
                </div>

            </div>
            <div class="close" @click="close" v-show="showChat">
                <i class="el-icon-close"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            chatList:[],
            list:[],
            value:'',
            currentId:null,
            showChat:false
        }
    },
    created(){
        this.getChatList();
        this.getInfo();
    },
    methods:{
        getChatList(){
            this.$api.getChatList().then(res=>{
                if(res.data.status==200){
                    this.chatList=res.data.data;
                }
            })
        },
        selectChat(data){
            this.currentId=data;
            this.showChat=true;
            this.getChat();
            
        },
        getChat(){
            this.$api.getChat({id:this.currentId}).then(res=>{
                if(res.data.status==200){
                   console.log(res.data)
                   this.list=res.data.data;
                   this.scrollToBottom();

                   
                }
            })
        },
        send(){
            console.log(this.value)
            let data={
               title:this.value,
               isadmin:1,
                user_id:this.currentId
            }
            this.$api.addChat(data).then(res=>{
                if(res.data.status==200){
                    this.list.push(data)
                    this.scrollToBottom();
                    this.value='';
                    

                }
            })
        },
        scrollEvent(e){
            console.log(e)
        },
        scrollToBottom(){
            setTimeout(()=>{

                document.querySelector('.chat-box').scrollTo({top:50000})
            })
        },
        close(){
            this.showChat=false;
        },
        //间隔1分钟获取数据
        getInfo(){
            clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
                this.getChatList()
                if(this.showChat){
                    this.getChat();
                }
                this.getInfo()
            },60000)
        }

        
    }
}


</script>

<style scoped lang="less">

.container{
    height:480px;
    // background:blue;
    display:flex;
    padding:20px;
    .chat-list{
        width:400px;
        height:420px;
        background:red;
        padding:10px;
        .list-item{
            margin:10px auto;
            background:skyblue;
            width:360px;
            height:40px;
        }
    }
    .chat{
        width:500px;
        height:420px;
        background:pink;
        // margin-left:20px;    
        .chat-box{
            padding:10px;
            width:100%;
            height:380px;
            // background:red;
            overflow-y:scroll;

            .item{
                width:460px;
                // background:skyblue;
                margin:10px 0;
                overflow:hidden;
                line-height:36px;
                .message{
                    // background:white;
                    padding:0 10px 0 10px;
                    max-width:300px;
                    display:inline-block;
                    border-radius:5px;
                }
                .left{
                    background:white;
                }
                .right{
                    float:right;
                    background:rgb(126, 206, 126);
                }
            }
        }
       
    }
    .close{
        
        background:red;
        height:30px;
        padding:5px;
        cursor:pointer;

    }
}


</style>