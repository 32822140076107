<template>
<div> 
    <el-upload
        ref="upload"
        :action="url"
        list-type="picture-card"
        :auto-upload="false"
        :limit="2"
        :headers="header"
        :on-success="handleSuccess"
        :before-upload="preUpload"
        
        name="kaidi"
        >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
            <img
                class="el-upload-list__item-thumbnail"
                :src="file.url" alt=""
            >
            <span class="el-upload-list__item-actions">
                <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
                >
                <i class="el-icon-zoom-in"></i>
                </span>
                <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
                >
                <i class="el-icon-download"></i>
                </span>
                <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
                >
                <i class="el-icon-delete"></i>
                </span>
            </span>
            </div>
        </el-upload>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

</div>
</template>
<script>
import {imgUpload} from '@/api'
  export default {
    
    data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        url:imgUpload,
        header:{Authorization:'Bearer '+this.$store.state.login.userInfo.token}
      };
    },
    methods: {
        submitUpload() {
        this.$refs.upload.submit();
      },
      handleRemove(file) {
        console.log(file);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleDownload(file) {
        console.log(file);
      },
      handleSuccess(response, file, fileList){
       
        this.$emit('imgUrl',response);
        
      

      },
      preUpload(file){
        return new Promise(resolve=>{
            console.log('上传图片时',file)

            const obj = new FileReader();
            let canvas = document.createElement('canvas')
            let img = document.createElement('img')
            //img.src =imgBase
            img.onload=()=>{
                console.log(canvas.width,'canvas')
                console.log(img.width,'canvas')
                canvas.width=750
                canvas.height=750
                // canvas.width=300
                // canvas.height=300
                // canvas.width=img.width
                // canvas.height=img.height
                let  ctx = canvas.getContext('2d')
                ctx.drawImage(img,0,0,750,750)
                // ctx.drawImage(img,0,0,300,300)
                //ctx.drawImage(img,0,0,img.width,img.height)
                let newImgBase = canvas.toDataURL('image/jpeg',0.7)
                console.log('压缩后',newImgBase)
                let binary = atob(newImgBase.split(',')[1])
              let array = []
              for(var i=0;i<binary.length;i++){
                array.push(binary.charCodeAt(i))
              }
              resolve(new Blob([new Uint8Array(array)],{type:'image/jpeg'}))
                //return this.dataURLtoBlob(newImgBase,imgType)
                
            }
            obj.onload=()=>{
                // document.querySelector('#old').src=obj.result
                console.log(obj.result)
                //return this.handelImg(obj.result,0.9,'image/jpeg')
                img.src=obj.result
            }
            obj.readAsDataURL(file)
        })
      },
     
       
        
    }
  }
</script>