<template>

    <div class="login">
        <div class="center ">
            <div class="title">
                <h2>登录</h2>
            </div>
            <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="账号" prop="account">
                    <el-input type="text" v-model="loginForm.account"  @keydown.enter.native="handleKeyDown" ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                    <el-input type="text" v-model="loginForm.pass" show-password @keydown.enter.native="handleKeyDown"></el-input>
                </el-form-item>
                
                
                <el-form-item>
                    <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
                    
                </el-form-item>
            </el-form>
            
            
           
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex';

import { menu} from '@/router/menu'
import { ruleMenu } from '@/utils/common'
import router,{ baseRoutes } from '@/router'
import {cloneDeep} from 'lodash'
import store from '@/store'

export default {
    data(){
      
        return {
            
            loginForm:{
                account:'',
                pass:''
            },
            rules:{
                account: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                   
                    
                ],
                pass:[
                    {required:true,message:'请输入密码',trigger:'blur'}
                ]
            }
        }
    },
    methods: {
        ...mapMutations('login',['setUser']),
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.login({user:this.loginForm.account,pwd:this.loginForm.pass})
            
            //alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async login(params){
        let res =await this.$api.login(params)
        console.log('登录成功---',res)
        if(res.data.status==200){

            // console.log('后台权限--',res.data.menu)
            // console.log('前台权限',menu)
            // let newMenu = ruleMenu(menu,res.data.menu)
            // console.log('匹配后',newMenu)
            // store.commit('menu/setMenu',newMenu)
            // let base = cloneDeep(baseRoutes)
            // base.children.push(...newMenu)
            // console.log('base---',base)
            // router.addRoute(base)



            this.setUser({user:res.data.info.name,'token':res.data.token})
            this.$router.push('/')
        }else if(res.data.status==404){
            this.$message('账号或密码错误');
        }

      },
      handleKeyDown(){
        this.submitForm('loginForm')
      }
    }
}


</script>
<style scoped lang="less">

.login{
    height:100%;
    width:100%;
    background:#f5f5f5;
    padding-top:1px;
    .center{
        height:260px;
        width:400px;
        background:white;
        margin:100px auto;
        padding:20px;
        .title{
            text-align:center;
        }
      
        
    }
}


</style>