<template>
    <div class="list">
        <Breadcrumb></Breadcrumb>
        <div class="header"></div>
        <div class="btn">
            <el-button type="primary" size="mini">订单汇总</el-button>
            <el-button type="success" size="mini">订单导出</el-button>
        </div>
        <div class="content">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column type="selection" width="40"></el-table-column>
                <el-table-column prop="id" label="ID" width="55"></el-table-column>
                <el-table-column prop="ordernum" label="订单编号" width="110">
                    <template slot-scope="scope">
                        <span class="orderDetail" @click="openDrawer(scope.row)">{{ scope.row.ordernum }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="user" label="用户" width="50" ></el-table-column>
                <el-table-column prop="status" label="状态" ></el-table-column>
                <el-table-column label="操作"
                width="150">
                <template slot-scope="scope">
                    <el-select v-model="value" placeholder="请选择状态" @change="selectChange(scope.row)">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <!-- <el-button
                    icon="el-icon-edit"
                    size="mini"
                    @click="handleEdit(scope.$index, scope.row)">改变状态</el-button> -->
                    
                </template>
            </el-table-column>
                <el-table-column prop="amount" label="订单金额"></el-table-column>
                <el-table-column prop="name" label="姓名" ></el-table-column>
                <el-table-column prop="phone" label="电话" ></el-table-column>
                <el-table-column prop="address" label="地址" ></el-table-column>
            <el-table-column prop="date" label="日期"></el-table-column>
                
            </el-table>
        </div>
        <Pagination 
        :pageSize="pageSize"
        :total="listTotal"
        @pageCurrentChange="pageCurrentChange"
        @pageSizeChange="pageSizeChange"
        ></Pagination>
        <OrderDetail ref="orderDetail" ></OrderDetail>
      


        
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import OrderDetail from './OrderDetail.vue'

export default {
    components:{
        Pagination,
        OrderDetail
    },   
    data(){
        return {
            tableData:[],
            pageSize:10,
            listTotal:0,
            value:'',
            //currentPage:1,
            options: [{
                value: '1',
                label: '待配送'
                }, {
                value: '2',
                label: '待配送已支付'
                }, {
                value: '3',
                label: '已完成未支付'
                }, {
                value: '4',
                label: '已完成'
                }],
                
        }
    },
    mounted(){
        this.getOrderList({page:1,pageSize:this.pageSize});
    },
    methods: {
        async getOrderList(params){
            let data = await this.$api.getOrderList(params);
            if(data.data.status==200){
                this.listTotal = data.data.count;
                this.tableData=data.data.data;
            }
            
        },
        pageCurrentChange(current){
            //this.currentPage=current;
            this.getOrderList({page:current,pageSize:this.pageSize})

        },
        pageSizeChange(data){
            this.pageSize=data;
        },
        openDrawer(number){
            this.$refs.orderDetail.ordernum=number.ordernum;
            
            this.$refs.orderDetail.titleData.push(number);
            this.$refs.orderDetail.drawer=true;
            
        },
        selectChange(row){
            console.log(row,this.value)
            this.$api.changeOrderStatus({id:row.id,status:this.value}).then(res=>{
                console.log(res)
                if(res.data.status==200){
                    
                    this.$message('改变成功')
                }else{
                    this.$message('改变失败，稍后再试')
                }
            })

        }
      
    }
}


</script>

<style lang="less" scoped>
.list{
    margin:10px;
    .btn{
        margin-top:10px;
        margin-bottom:10px;
    }
    .orderDetail{
        color:blue;
        cursor:pointer;
    }


}

</style>