export default {
    namespaced:true,
    state:{
        userInfo:{
            user:'',
            token:''
        }
        
    },
    mutations:{
        setUser(state,payload){
            state.userInfo = payload

        },
        removeUser(state,payload){
            state.userInfo = {
                user:'',
                token:''
            }
        }
    }
}