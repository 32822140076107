<template>
    <div class="role">
        <Breadcrumb></Breadcrumb>
        <div class="tables">
            <el-table
            :data="roleList"
            border
            style="width: 100%">
                <el-table-column
                prop="id"
                label="编号"
                width="200">
                </el-table-column>
                <el-table-column
                prop="role_name"
                label="名字"
                width="200">
                </el-table-column>
                <el-table-column
                prop="info"
                label="描述"
                width="200">
                </el-table-column>
                <el-table-column
                
                label="操作"
                width="200">
                    <template slot-scope="scope">
                        <el-button @click="assignMenu(scope.row)" type="text" size="small">分配菜单权限</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-drawer
            title="分配菜单权限"
            :visible.sync="drawer"
            direction="ttb"
            :before-close="handleClose"
            size="100%" >
                <div class="drawer-box">

                    <el-checkbox-group v-model="box">
                     
                         <div v-for="item in menu">
    
                            <div :class="item.parent_id==0?'box':'box-c'">
                                <el-checkbox  :label="item.id" name="order">{{ item.name }}</el-checkbox>
                            
                            </div>
                         </div>
          
                    </el-checkbox-group>    
                </div>
                <el-button type="primary" @click="btn">确 定</el-button>
            </el-drawer>

    </div>
</template>
<script>
export default {
    data(){
        return {
            roleList:[],
            drawer:false,
            box:[],
            menu:[],
            currentId:null

           
        }
    },
    methods:{
        getRole(){
            this.$api.getRole().then(res=>{
                if(res.data.status==200){
                    this.roleList=res.data.data;
                }
            })
        },
        getMenu(){
            this.$api.getMenu().then(res=>{
                if(res.data.status==200){
                    const menu=res.data.data;
                    let newMenu=[]
                    menu.forEach(item=>{
                        if(item.parent_id==0){
                            newMenu.push(item)
                        }
                        menu.forEach(ele=>{
                            if(item.id==ele.parent_id){
                                newMenu.push(ele)    
                            }
                        })
                        
                    })
                    this.menu=newMenu;

                    console.log('45',this.menu)
                }

            })
        },
        assignMenu(row){
            this.currentId = row.id;
            this.drawer=true;
            this.$api.getRoleMenuId({id:row.id}).then(res=>{
                if(res.data.status==200){
                    console.log('role_id',res.data.data)
                    this.box=res.data.data.map(item=>item.id)
                    console.log(this.box)
                }
            })
            
        },
        btn(){
            console.log(this.box)
            this.$api.updateRoleMenu({id:this.currentId,menu:this.box}).then(res=>{
                if(res.data.status==200){
                    console.log('updateRoleMenu',res.data)
                    this.drawer=false;
                    this.box=[]
                    this.$message({
                        message:'分配菜单成功',
                        type:'success'
                     })
                    
                }else{
                    this.$message.error('发生错误，请稍后再试')
                }
            })
        },
        handleClose(done){
            this.box=[]
            done();
        }
    },
    created(){
        this.getRole();
        this.getMenu();
    }
}


</script>
<style scoped lang="less">
.role{
    padding:10px;
    // background:#f5f5f5;
    background:white;
    
    .tables{
        padding:10px;
        background:white;
        border-radius:10px;
    }
    .drawer-box{
        // background:yellow;
        width:80%;
        margin:0 auto;

        .box{
            height:50px;
            width:100%;
            // background:red;
            line-height:50px;
           
        }
        .box-c{
            // background:blue;
            padding-left:10px;
        }
    }
}

</style>