import base from './base'
import store from '@/store'
import axios from 'axios'

function getToken(){
    return {Authorization:'Bearer ' + store.state.login.userInfo.token}
}

const api = {
    getHomeCount(){
        return axios.get(base.host+base.homeData,{headers:getToken()})
    },
    getHomeFormat(){
        return axios.get(base.host+base.format,{headers:getToken()})
    },
    getHomeOrder(){
        return axios.get(base.host+base.homeOrder,{headers:getToken()})
    },
    getGoodsList(params){
        return axios.get(base.host+base.goodsList,{params,headers:getToken()})
    },
    goodsSearch(params){
        return axios.get(base.host+base.goodsSearch,{params,headers:getToken()})

    },
    deleteGoods(params){
        return axios.get(base.host+base.deleteGoods,{params,headers:getToken()})
    },
    getCategory(){
        return axios.get(base.host+base.getCategory,{headers:getToken()})
    },
    addGoods(data){
        return axios.post(base.host+base.addGoods,data,{headers:getToken()})
    },
    getOrderList(params){
        return axios.get(base.host+base.getOrderList,{params,headers:getToken()})
    },
    selectGoods(params){
        return axios.get(base.host+base.selectGoods,{params,headers:getToken()})
    },
    submitOrder(data){
        return axios.post(base.host+base.submitOrder,data,{headers:getToken()})
    },
    getOrderDetail(params){
        return axios.get(base.host+base.getOrderDetail,{params,headers:getToken()})
    },
    login(params){
        return axios.post(base.host+base.login,params)
    },
    permission(params){
        return axios.get(base.host+base.permission,{params,headers:getToken()})
    },
    getAdmin(){
        return axios.get(base.host+base.getAdmin,{headers:getToken()})

    },
    adminIsuse(params){
        return axios.get(base.host+base.adminIsuse,{params,headers:getToken()})
    },
    getUserRole(params){
        return axios.get(base.host+base.getUserRole,{params,headers:getToken()})
    },
    updateUserRole(params){
        return axios.post(base.host+base.updateUserRole,params,{headers:getToken()})
    },
    addUser(params){
        return axios.post(base.host+base.addUser,params,{headers:getToken()})
    },
    searchUser(params){
        return axios.get(base.host+base.searchUser,{params,headers:getToken()})
    },
    deleteUser(params){
        return axios.post(base.host+base.deleteUser,params,{headers:getToken()})
    },
    getRole(){
        return axios.get(base.host+base.getRole,{headers:getToken()})

    },
    getMenu(){
        return axios.get(base.host+base.getMenu,{headers:getToken()})

    },
    getRoleMenuId(params){
        return axios.get(base.host+base.getRoleMenuId,{params,headers:getToken()})

    },
    updateRoleMenu(params){
        return axios.post(base.host+base.updateRoleMenu,params,{headers:getToken()})
    },
    getChatList(params){
        return axios.get(base.host+base.getChatList,{params,headers:getToken()})
    },
    getChat(params){
        return axios.get(base.host+base.getChat,{params,headers:getToken()})
    },
    addChat(params){
        return axios.post(base.host+base.addChat,params,{headers:getToken()})
    },
    changeOrderStatus(params){
        return axios.post(base.host+base.changeOrderStatus,params,{headers:getToken()})
    },


}
export function permission(params){
    return axios.get(base.host+base.permission,{params,headers:getToken()})
}

export default api
export const imgUpload = base.host+base.imgUpload
